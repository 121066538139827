import React, { Component } from "react";
import ReactLottie from "react-lottie";

import JiqiLoadingLottie from "assets/lottie/jiqi_loading.json";
import "stylesheets/components/loader/index.scss";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: JiqiLoadingLottie,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

class LoadingModal extends Component {
  state = {
    isPaused: false,
    isDuplicated: document?.getElementsByClassName('at-loader-container').length > 1
  };

  componentDidMount = () => {
    let tmpLength = document.getElementsByClassName("at-loader-container")?.length || 0
    if(tmpLength > 0 && document.getElementsByClassName("at-loader-container")[0]) {
      document.getElementsByClassName("at-loader-container")[0].style.opacity = 1
    }
  }

  render = () => {
    const { loaderClassName, message, loaderContainerClass } = this.props;
    const splitMessage = message ? message.split("\n") : "";

    return (
      <div className={`at-loader-container ${loaderContainerClass} ${document?.getElementsByClassName('at-loader-container').length > 1 ? "at-loader-duplicated" : ""}`}>
        <div className={`at-loader ${loaderClassName}`}>
          <ReactLottie
            options={defaultOptions}
            isPaused={this.state.isPaused}
          />
        </div>
        {message && splitMessage ? (
          <div className="at-loader-message">
            {splitMessage.map((item, index) => (
              <p key={index} style={{ maxWidth: "400px" }}>
                {item}
              </p>
            ))}
          </div>
        ) : (
          <div className="at-loader-message">{message}</div>
        )}
      </div>
    );
  };
}


export default LoadingModal;